<!-- src/components/AboutPage.vue -->
<template>
  <div class="hello">
    <p>
      Hi，先生！<br>
      欢迎来到Dolphin
    </p>
    <h3>这是一个交易系统</h3>
    <li><router-link to="/info">计算器</router-link></li>
    <br>
    <!--<ul>
      <li><a href="" target="_blank" rel="noopener">资讯</a></li>
      <li><a href="" target="_blank" rel="noopener">交易</a></li>
    </ul>
    <h3></h3>-->
    奇介小星@2024
  </div>
</template>