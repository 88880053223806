// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AboutPage from '@/views/AboutPage.vue';
import TradePage from '@/views/TradePage.vue';
import InfoPage from '@/views/InfoPage.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },{
    path: '/info',
    name: '信息查询',
    component: InfoPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/trade',
    name: 'Trade',
    component: TradePage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;