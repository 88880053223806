<template>
  <div>
    <h2>股票计算器</h2>
    <el-form @submit.prevent="submitForm">
      <el-form-item label="股票代码">
        <el-input v-model="formData.stock_code" type="string" placeholder="请输入股票代码，如: 430017"></el-input>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          v-model="formData.start_time"
          type="datetime"
          placeholder="选择开始时间"
          value-format="YYYY-MM-DDTHH:mm:ss.000Z"
        />
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="formData.end_time"
          type="datetime"
          placeholder="选择结束时间"
          value-format="YYYY-MM-DDTHH:mm:ss.000Z"
        />
      </el-form-item>
      <el-form-item label="时间周期">
        <el-select v-model="formData.period" placeholder="请选择周期">
          <el-option label="1分钟" value="1"></el-option>
          <el-option label="5分钟" value="5"></el-option>
          <el-option label="15分钟" value="15"></el-option>
          <el-option label="30分钟" value="30"></el-option>
          <el-option label="60分钟" value="60"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">计算</el-button>
      </el-form-item>
    </el-form>

    <!-- el-card 只有在 showResults 为 true 时才显示 -->
    <div v-if="showResults">
      <el-card style="width: 480px" shadow="always">
        <p>股票代码：{{ stockInfo.code }}</p>
        <p>行业：{{ stockInfo.industry }}</p>
        <p>名称：{{ stockInfo.name }}</p>
        <p>总成交量（元）：{{ formattedAmount }}</p>
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { API_BASE_URL } from '../config';

export default {
  name: 'StockCalculator',
  data() {
    return {
      volume_price_sum: 0, // 默认状态，初始化为0
      stockInfo: {}, // 用于存储股票信息
      formData: {
        stock_code: '601998',
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        period: '5', // 默认周期设置为 "5"
        cal_type: "volume_price_sum"
      },
      showResults: false, // 控制 el-card 显示的标志
    };
  },
  computed: {
    formattedAmount() {
      // 使用计算属性来格式化金额
      return this.formatMoney(this.volume_price_sum);
    },
  },
  methods: {
    submitForm() {
      // 确保开始时间和结束时间都已选择
      if (!this.formData.start_time || !this.formData.end_time) {
        ElMessage.error('请选择开始时间和结束时间');
        return;
      }

      // 将开始时间和结束时间组合为数组
      this.formData.query_time = [this.formData.start_time, this.formData.end_time];

      axios.post(`${API_BASE_URL}/calculate`, this.formData)
        .then(({ data }) => {
          if (data.success) {
            this.volume_price_sum = data.result.total_trade_amount; // 更新总成交量
            this.stockInfo = {
              code: data.result.stock_info.code,
              industry: data.result.stock_info.industry,
              name: data.result.stock_info.name,
            }; // 更新股票信息
            this.showResults = true; // 显示 el-card
          } else {
            // 展示错误消息
            ElMessage.error(data.message);
          }
        })
        .catch(error => {
          console.error('API 调用出错:', error);
          ElMessage.error('服务不可达');
        });
    },
    formatMoney(amount) {
      // 检查 amount 是否是有效的数字
      if (typeof amount === 'number' && isFinite(amount)) {
        // 如果是数字，则格式化金额
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); // 添加千位分隔符并保留两位小数
      } else {
        // 如果不是数字，返回空字符串或者默认值
        return '0.00'; // 可以根据需要返回其他默认值
      }
    },
  },
};
</script>