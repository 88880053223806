<template>
  <div id="app">
    <!-- 菜单控制按钮 -->
    <button class="menu-toggle" @click="toggleMenu">
      {{ isMenuCollapsed ? '>' : '<' }}
    </button>

    <!-- 侧边菜单 -->
    <aside class="menu" :class="{ 'collapsed': isMenuCollapsed }">
      <ul v-if="!isMenuCollapsed">
        <li v-for="item in menuItems" :key="item.path">
          <router-link :to="item.path" class="menu-item">{{ item.name }}</router-link>
        </li>
      </ul>
    </aside>

    <!-- 页面内容 -->
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      menuItems: [
        { name: '首页', path: '/' },
        { name: '查询', path: '/info' },
        { name: '关于', path: '/about' },
        { name: '联系', path: '/contact' },
      ],
      isMenuCollapsed: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
  },
};
</script>

<style>
#app {
  display: flex;
  height: 100vh;
}

.menu-toggle {
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 100;
  background: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.menu {
  width: 250px;
  height: 100%;
  background: #333;
  color: #fff;
  transition: width 0.3s;
  overflow: hidden;
  position: fixed;
}

.menu.collapsed {
  width: 60px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: block;
  padding: 15px;
  color: #fff;
  text-decoration: none;
  transition: background 0.3s;
}

.menu-item:hover {
  background: #444;
}

main {
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s;
  margin-left: 250px;
}

.menu.collapsed + main {
  margin-left: 60px;
}
</style>