<template>
  <el-form @submit.prevent="handleSubmit">
    <el-form-item label="初始价格">
      <el-input v-model.number="formData.init_price" type="number"></el-input>
    </el-form-item>
    <el-form-item label="数量">
      <el-input v-model.number="formData.quantity" type="number"></el-input>
    </el-form-item>
    <el-form-item label="杠杆">
      <el-input v-model.number="formData.leverage" type="number"></el-input>
    </el-form-item>
    <el-form-item label="次数">
      <el-input v-model.number="formData.times" type="number"></el-input>
    </el-form-item>
    <el-form-item label="合约选择">
      <el-select v-model="formData.inst_id" placeholder="请选择">
        <el-option label="BTC-USDT-SWAP" value="BTC-USDT-SWAP"></el-option>
        <!-- 其他合约选项 -->
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit">提交</el-button>
    </el-form-item>
  </el-form>

  <el-table :data="orders" style="width: 100%" :default-sort="{prop: 'seq_id', order: 'ascending'}">
    <el-table-column prop="seq_id" label="序号"></el-table-column>
    <el-table-column prop="order_no" label="订单号"></el-table-column>
    <el-table-column prop="inst_id" label="合约ID"></el-table-column>
    <el-table-column prop="leverage" label="杠杆"></el-table-column>
    <!-- 其他列 -->
    <el-table-column prop="order_status" label="订单状态"></el-table-column>
  </el-table>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
const formData = ref({
  init_price: null,
  quantity: null,
  leverage: null,
  times: null,
  inst_id: 'BTC-USDT-SWAP'
});

const orders = ref([]);



const handleSubmit = async () => {
  try {
    // 替换 `/api/endpoint` 为后端API接口地址
    const apiEndpoint = 'http://127.0.0.1:5000/api/calculate'
    const response = await axios.post(apiEndpoint, formData.value);
    if (response.data && response.data.data) {
      orders.value = response.data.data.sort((a, b) => a.seq_id - b.seq_id);
    } else if (response.data && response.data.message) {
      ElMessage.error(response.data.message);
    }
    
  } catch (error) {
    console.error('Error while fetching orders:', error);
  }
};
</script>

<style scoped>
/* 样式代码 */
</style>